<template>
  <div class="wrapper">
    <div class="controls">
      <div class="button-controls">
        <button class="filter button" @click="toggleFilter = !toggleFilter">
          Toggle Filter
        </button>
        <button
          :class="['refresh', 'button', isLoading ? 'loading' : '']"
          @click="getMarketItems"
        >
          Refresh
        </button>
      </div>
      <div class="order">
        <div class="aselect">
          <div class="selector" @click="toggleDropdown = !toggleDropdown">
            <div class="label">
              <span>{{ searchSorting }}</span>
            </div>
            <div class="arrow"></div>
            <div :class="{ hidden: !toggleDropdown }">
              <ul>
                <li
                  @click="setSearching(sortItem)"
                  :key="sortItem"
                  v-for="sortItem in sortList"
                >
                  {{ sortItem }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="[`main-grid`, toggleFilter ? 'sidebar-open' : '']">
      <Sidebar
        @all="filterAll"
        @armours="filterArmour"
        @weapons="filterWeapons"
        @potions="filterConsumables"
        @resources="filterResources"
        v-if="toggleFilter"
      />
      <div class="content">
        <template v-if="isLoading">
          <div class="grid">
            <ItemLoading v-for="index in 12" :key="index" />
          </div>
        </template>
        <template v-else>
          <h3 v-if="currentMarketInventory.length === 0">No Items Listed</h3>
          <div class="grid">
            <MarketplaceItem
              v-for="marketItem in currentMarketInventory"
              :item="marketItem"
              :key="marketItem._id"
            />
          </div>
        </template>

        <BlueButton
          v-if="!loadedAllItems"
          size="small"
          @click="loadMoreMarketItems"
          class="loading-button"
        >
          Load More
        </BlueButton>
        <h4 v-else>No More Items To Load</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";

import Sidebar from "../../components/AdvancedMarketplace/Sidebar.vue";
// import { useRoute } from "vue-router";
import ItemLoading from "../../components/AdvancedMarketplace/ItemLoading.vue";
import MarketplaceItem from "../../components/AdvancedMarketplace/MarketplaceItem.vue";
import BlueButton from "../../components/Buttons/BlueButton.vue";
import { useUser } from "../../composables/user";
import Constants from "../../consts/constants";
export default {
  displayName: "MarketplaceHome",
  components: {
    MarketplaceItem,
    Sidebar,
    BlueButton,
    ItemLoading,
  },
  setup() {
    const error = ref(null);
    const joepegs = ref(null);
    const heroData = ref(null);
    const hasId = ref(false);
    const id = ref("");
    const account = ref("");
    const isLoading = ref(true);
    const store = useStore();
    const toggleFilter = ref(false);
    const searchSorting = ref("Recent");
    const toggleDropdown = ref(false);
    const { isCorrectChain, address, isActivated } = useUser();

    const itemContainer = ref(null);

    // const marketplaceItems = ref([]);
    // const loading = ref(false);
    const loadingMoreItems = ref(false);
    const page = ref(1);
    const loadedAllItems = ref(false);
    // const viewMyItems = ref(false);
    const searchDelayTimer = ref(null);

    // const filterType = ref("");
    const currentSearchTerm = ref("");
    // const marketplaceItems = ref([]);
    // const loading = ref(false);
    // const loadingMoreItems = ref(false);
    // const page = ref(1);
    // const loadedAllItems = ref(false);
    // const viewMyItems = ref(false);

    const sortList = [
      "Recent",
      "Oldest",
      "Price Desc",
      "Price Asc",
      "Rarity Desc",
      "Rarity Asc",
      "Attack Desc",
      "Defense Desc",
      "Magic Desc",
      "Speed Desc",
      "Crafting Desc",
      "Gathering Desc",
      "Health Desc",
    ];

    // const nftTrade = ref(null);
    // const kalao = ref(null);

    // const route = useRoute();

    onMounted(async () => {
      await getMarketItems();
      account.value = store.state.account;
    });

    const filterAll = async () => {
      await filterItems(Constants.filterTypes.all);
    };
    const filterArmour = async () => {
      await filterItems(Constants.filterTypes.armour);
    };
    const filterWeapons = async () => {
      await filterItems(Constants.filterTypes.weapons);
    };
    const filterConsumables = async () => {
      await filterItems(Constants.filterTypes.consumables);
    };
    const filterResources = async () => {
      await filterItems(Constants.filterTypes.resources);
    };
    const filterQuest = async () => {
      await filterItems(Constants.filterTypes.quest);
    };
    const filterItems = async (filter) => {
      // filterType.value = filter;
      store.commit("marketplace/setMarketFilter", filter);
      await getMarketItems();
    };
    /**
     * Watching messages.
     * After every change in message, scrolling to the bottom of the message area.
     * Also assigning read message to the latest message count if chat open
     */
    watch(
      searchSorting,
      (value) => {
        store.dispatch("marketplace/processSorting", value);
        getMarketItems();
      },
      { deep: true }
    );

    const setSearching = (item) => {
      toggleDropdown.value = true;
      searchSorting.value = item;
    };

    /**
     * By default always fetching page 1 of the respective type but maintaing the sort type
     */
    const searchTimeOut = () => {
      if (searchDelayTimer.value) {
        clearTimeout(searchDelayTimer.value);
        searchDelayTimer.value = null;
      }
      searchDelayTimer.value = setTimeout(() => {
        console.log(currentSearchTerm.value);
        store.commit("marketplace/setSearchTerm", currentSearchTerm.value);
        getMarketItems();
      }, 500);
    };

    /**
     * Fetching the respective next page and maintaing everything else.
     * Then processing the data and adding it to existing data
     */
    const loadMoreMarketItems = async () => {
      loadingMoreItems.value = true;
      const marketplaceApiQuery = `marketplace/get-items?type=${
        filterType.value
      }&page=${++page.value}&sortBy=${sortBy.value}&order=${order.value}`;
      const response = await fetch(Constants.apiUrl + marketplaceApiQuery);
      const data = await response.json();
      if (data.success) {
        if (data.marketplaceItems.length === 0) {
          loadedAllItems.value = true;
          store.commit("setNotification", "Loaded All Items");
        } else {
          store.dispatch(
            "marketplace/loadMoreMarketItems",
            data.marketplaceItems
          );
        }
      }
      loadingMoreItems.value = false;
    };

    /**
     * By default always fetching page 1 of the respective type but maintaing the sort type
     */
    const getMarketItems = async () => {
      isLoading.value = true;
      await store.dispatch("marketplace/updateMarketplaceItems");
      isLoading.value = false;
      //   resetFilter();
    };

    const currentMarketInventory = computed(() => {
      let results = store.state.marketplace.marketData;
      return results;
    });
    const filterType = computed(() => {
      return store.state.marketplace.marketFilter;
    });
    const sortBy = computed(() => {
      return store.state.marketplace.sortBy;
    });
    const order = computed(() => {
      return store.state.marketplace.order;
    });

    return {
      // data
      currentMarketInventory,
      heroData,
      joepegs,
      hasId,
      error,
      id,
      isLoading,
      toggleFilter,
      searchSorting,
      sortList,
      toggleDropdown,
      currentSearchTerm,
      itemContainer,
      address,
      isActivated,
      loadedAllItems,
      // methods
      getMarketItems,
      setSearching,
      searchTimeOut,
      loadMoreMarketItems,
      filterAll,
      filterArmour,
      filterWeapons,
      filterConsumables,
      filterResources,
      filterQuest,
      filterItems,
      isCorrectChain,
    };
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: block;
  max-width: 1616px;
  margin: 0 auto;
  padding: 0 1rem;

  .controls {
    display: grid;
    grid-template-columns: 1fr 250px;
    margin: 1rem 0;
    .button-controls {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
      grid-gap: 1rem;
      .button {
        width: 40px;
        height: 40px;
        background-size: 75%;
        padding: 5px;
        display: grid;
        align-items: center;
        justify-items: center;
        text-indent: -9999px;
        box-shadow: none;
        border: 2px solid #404649;
        background-color: transparent;
        border-radius: 10px;
        &:hover:after {
          opacity: 1;
        }
        &:after {
          width: 30px;
          height: 30px;
          content: "";
          position: absolute;
          background-position: center center;
          background-repeat: no-repeat;
          opacity: 0.6;
        }
        &.filter:after {
          background-image: url("../../assets/ui/filter.svg");
        }
        &.refresh {
          &::after {
            background-image: url("../../assets/ui/refresh.svg");
          }
          &.loading {
            &:after {
              animation: rotate-animation 1s infinite linear;
            }
          }
        }
      }
    }

    .order {
      max-width: 250px;

      /* Dropdown */
      .aselect {
        width: 100%;
        z-index: 50;
        position: relative;

        .selector {
          border: 2px solid #404649;
          background-color: transparent;
          border-radius: 10px;
          position: relative;
          z-index: 1;

          .expanded {
            transform: rotateZ(180deg) translateY(2px);
          }
          .label {
            font-size: 16px;
            color: #888;
            min-height: 40px;
            display: grid;
            align-items: center;
            position: relative;
            :after {
              content: "";
              position: absolute;
              right: 20px;
              top: 40%;
              width: 0;
              height: 0;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-top: 8px solid #ffffff7a;
              transform: rotateZ(0deg) translateY(0px);
              transition-duration: 0.3s;
              transition-timing-function: cubic-bezier(0.59, 1.39, 0.37, 1.01);
            }
          }
        }
        ul {
          width: 100%;
          list-style-type: none;
          padding: 0;
          margin: 0;
          font-size: 16px;
          border: 1px solid #404649;
          position: absolute;
          z-index: 1;
          background: #0f1215;
          border-radius: 10px;
          top: -3px;
        }
        li {
          padding: 12px;
          color: #666;
          &:hover {
            color: white;
            background: #393f43;
          }
        }
        .current {
          background: #eaeaea;
        }
        .hidden {
          visibility: hidden;
        }
        .visible {
          visibility: visible;
        }
      }
    }
  }
  .main-grid {
    display: block;
    &.sidebar-open {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr 3fr;
    }
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 1rem;
    @media only screen and (max-width: 576px) {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    &.sidebar-open {
      grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    }
  }
  .loading-button {
    margin: 2rem auto;
  }
}
@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
